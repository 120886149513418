import React from "react";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <main>
      {/* login START */}
      <div className="sv-loginconatiner">
        <div className="sv-popupcontainer">
          <div className="sv-login_title">
            <Link to={"/"}>
              <img src="images/logonav.png" alt="images description" />
            </Link>
            <h5>Welcome to the community</h5>
          </div>
          <div className="sv-login-content sv-popup-content">
            <form className="sv-themeform">
              <fieldset>
                <div className="sv-themeform__wrap">
                  <div className="form-group">
                    <label className="sv-label">Phone Number</label>
                    <div className="sv-placeholderholder">
                      <input
                        type="number"
                        className="form-control sv-input-field"
                        defaultValue="amentotech@gmail.com"
                        required
                      />
                      <div className="sv-placeholder">
                        <span>12345678</span>
                        <em>*</em>
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <label className="sv-label">Password</label>
                    <div className="sv-placeholderholder">
                      <input
                        type="text"
                        className="form-control sv-input-field"
                        defaultValue="*********"
                        required
                      />
                      <div className="sv-placeholder">
                        <span>Enter password</span>
                        <em>*</em>
                      </div>
                    </div>
                  </div> */}
                  <div className="form-group">
                    <Link to="#" className="sv-pb-lg w-100">
                      {" "}
                      <span>Login now</span>
                      <i className="icon-arrow-right" />
                    </Link>
                  </div>
                  {/* <div className="sv-optioanl-or">
                    <span>OR</span>
                  </div>
                  <div className="sv-sginup-btn form-group">
                    <Link to={"/"} className="sv-pb btn-signup">
                      <img src="images/google.png" alt="images" />
                      Sign in with Google
                    </Link>
                  </div>
                  <div className="sv-lost-password form-group">
                    <Link to="/register">Join us today</Link>
                    <a
                      href="lost-password.html"
                      className="sv-password-clr_light"
                    >
                      Lost password?
                    </a>
                  </div> */}
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      {/* login START */}
    </main>
  );
};

export default Login;
