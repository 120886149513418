/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { FaFacebook, FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa";

const MobileApp = () => {
  return (
    <section className="sv-main-section">
      <div className="sv-sectionappdark vh-100 d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <div className="sv-sectionapptitle">
                <div className="sv-maintitle">
                  <h2 className="mw-100">Simple App for A Simple Life</h2>
                </div>
                <div className="sv-main-description">
                  <p>
                    for all you need to do, just <i>komwok</i>
                  </p>
                </div>
                <div className="d-flex gap-1">
                  <img src="/images/app.png" className="w-50" alt="" />
                  <img src="/images/googlelogo.png" className="w-50" alt="" />
                </div>
                <div className="sv-appcompat">
                  <h6>
                    <i className="icon-bell" />
                    <span> KomWok is compatible with</span> Android and iOS
                    devices
                  </h6>
                </div>
              </div>
            </div>
            <div className="align-self-lg-end col-md-6 col-sm-12 d-lg-flex d-none gap-2">
              <figure className="sv-appiamge">
                <img
                  src="images/indexv1/mobile.png"
                  alt="images-descp"
                  className="w-100"
                />
              </figure>
              <div className="d-inline-grid">
                <FaFacebook size={50} color="88048e" />
                <FaInstagram size={50} color="88048e" />
                <FaTwitter size={50} color="88048e" />
                <FaWhatsapp size={50} color="88048e" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileApp;
