import React from "react";
import { Link, useLocation } from "react-router-dom";

const ClientSidebar = () => {
  const location = useLocation().pathname;

  return (
    <main className="sv-main sv-bg-light">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-12 col-xl-12 d-none d-lg-block">
            <div className="sv-dbsidenav">
              <ul className="sv-dbnavlist">
                <li
                  className={`sv-dbnavlist-item ${
                    location === "/client/dashboard" ? "active" : ""
                  }`}
                >
                  <Link to="/client/dashboard">
                    <i className="icon-home" /> Display Name
                  </Link>
                </li>
                <li
                  className={`sv-dbnavlist-item ${
                    location === "/client/postajob" ? "active" : ""
                  }`}
                >
                  <Link to="/client/postajob">
                    <i className="icon-folder" /> Post a jobs
                  </Link>
                </li>
                <li className="sv-dbnavlist-item">
                  <Link to="#">
                    <i className="icon-message-square" /> Terms and conditions
                  </Link>
                </li>
                <li
                  className={`sv-dbnavlist-item ${
                    location === "/client/notification" ? "active" : ""
                  }`}
                >
                  <Link to="/client/notification">
                    <i className="icon-user" /> Notifications
                  </Link>
                </li>
                <li className="sv-dbnavlist-item">
                  <Link to="#">
                    <i className="icon-package" /> Payment
                  </Link>
                </li>
                <li className="sv-dbnavlist-item">
                  <Link to="#">
                    <i className="icon-settings" /> Delete Account
                  </Link>
                </li>
                <li className="sv-dbnavlist-item sv-poweroff">
                  <Link to="/">
                    <i className="icon-power" /> Logout
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ClientSidebar;
