import React, { useState } from "react";
import { Link } from "react-router-dom";
import services from "../../utils/services.json";
import {
  MdOutlineArrowCircleDown,
  MdOutlineArrowCircleUp,
} from "react-icons/md";
const HomeListing = () => {
  const [exploreAll, setExploreAll] = useState(false);
  return (
    <div className="sv-banner">
      <span className="sv-shade" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="sv-banner_title">
              <h1>Desire a different kind of freedom?</h1>

              <h3 className="text-white">
                Come in and discover the tool KOMWOK offers
              </h3>
            </div>
            <ul className="sv-mainbtnlist ">
              <li>
                <Link to="/provider/dashboard" className="sv-pb text-white">
                  I’m Service Provider
                </Link>
              </li>
              <li>
                <Link to="/client/dashboard" className="sv-pb sv-sp-white-border text-white">
                  I’m Service Seeker
                </Link>
              </li>
            </ul>
            <ul className="sv-explore-categories justify-content-center">
              {services.map(
                (service, index) =>
                  index < (exploreAll ? services.length : 10) && (
                    <Link to={"/JobDetail"} title={service.EnglishName}>
                      <li>
                        <div className="sv-explore-content">
                          <figure>
                            <img
                              src={service.Image}
                              className="rounded-circle"
                              style={{ width: 100, height: 100 }}
                              alt="images"
                            />
                          </figure>
                          <div
                            className="sv-explore-info overflow-hidden w-100"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <h6
                              className="overflow-hidden "
                              style={{ textOverflow: "ellipsis" }}
                            >
                              {service.EnglishName}
                            </h6>
                          </div>
                        </div>
                      </li>
                    </Link>
                  )
              )}
            </ul>
            <div
              className="text-center mt-5 text-white cursor-pointer"
              onClick={() => setExploreAll(!exploreAll)}
            >
              {exploreAll ? (
                <MdOutlineArrowCircleUp size={50} className="arrow-icon" />
              ) : (
                <MdOutlineArrowCircleDown size={50} className="arrow-icon" />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="sv-author-name">
        <span>Spartan’s long waves,</span>
        <em>James Cornor</em>
      </div> */}
    </div>
  );
};

export default HomeListing;
