/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <div className="container-fluid  mt-2">
        <div className="d-flex justify-content-end gap-2 align-items-center me-5">
          <img
            src="/images/english.png"
            alt=""
            className="rounded-img"
            style={{ height: 30, width: 30 }}
          />
          <img
            src="/images/spain.png"
            alt=""
            className="rounded-img"
            style={{ height: 30, width: 30 }}
          />
          <img
            src="/images/portugal.png"
            alt=""
            className="rounded-img"
            style={{ height: 30, width: 30 }}
          />
        </div>
      </div>
      <header className="navbar navbar-expand-lg sv-navbar">
        <div className="container-fluid">
          <Link className="navbar-brand" to={"/"}>
            <strong>
              <img
                src="/images/logonav.png"
                alt="image description"
                style={{ width: "30%" }}
              />
            </strong>
          </Link>
          <div className="sv-navbar-right">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="fa fa-bars" />
            </button>
            <nav
              className="collapse navbar-collapse sv-navbar-collapse"
              id="navbarNav"
            >
              <ul className="navbar-nav sv-navbar-nav">
                <li>
                  <Link className="sv-pb text-white" to="/client/dashboard">
                    I'am a Service Seeker{" "}
                  </Link>
                </li>
                <li>
                  <Link className="sv-pb text-white" to="/provider/dashboard">
                    I'am a Service Provider{" "}
                  </Link>
                </li>
                <li>
                  <Link className="sv-pb text-white" to="/register">
                    Sign Up
                  </Link>
                </li>
                <li>
                  <Link className="sv-pb text-white" to="/login">
                    Sign In{" "}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
