import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import SignUp from "../pages/Auth/signup";
import Login from "../pages/Auth/login";
import CardDetails from "../components/Home/CardDetails";

const HomeRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<SignUp />} />
      <Route path="/card/details/:id" element={<CardDetails />} />
    </Routes>
  );
};

export default HomeRoute;
