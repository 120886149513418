import React from "react";
import { Link } from "react-router-dom";

const SignUp = () => {
  return (
    <main>
      {/* login START */}
      <div className="sv-loginconatiner sv-signup">
        <div className="sv-popupcontainer">
          <div className="sv-login_title">
            <Link to={"/"}>
              <img src="images/logonav.png" alt="images description" />
            </Link>
            <h5>We love to see you joining our community</h5>
          </div>
          <div className="sv-login-content">
            <form className="sv-themeform">
              <fieldset>
                <div className="sv-themeform__wrap">
                  <div className="form-group">
                    <label className="sv-label">Role</label>
                    <div className="sv-placeholderholder">
                      <select
                        type="text"
                        className="form-control sv-input-field"
                        required="required"
                      >
                        <option selected hidden>
                          Please Choose Your Role....
                        </option>
                        <option value="client">Service Provider</option>
                        <option value="provider">Service Seeker</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="sv-label">First name</label>
                    <div className="sv-placeholderholder">
                      <input
                        type="text"
                        className="form-control sv-input-field"
                        required="required"
                      />
                      <div className="sv-placeholder">
                        <span>Enter your first name</span>
                        <em>*</em>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="sv-label">Last name</label>
                    <div className="sv-placeholderholder">
                      <input
                        type="text"
                        className="form-control sv-input-field"
                        required="required"
                      />
                      <div className="sv-placeholder">
                        <span>Enter your last name</span>
                        <em>*</em>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="sv-label">Email address</label>
                    <div className="sv-placeholderholder">
                      <input
                        type="email"
                        className="form-control sv-input-field"
                        required="required"
                      />
                      <div className="sv-placeholder">
                        <span>Enter email address</span>
                        <em>*</em>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="sv-label">Password</label>
                    <div className="sv-placeholderholder">
                      <input
                        type="password"
                        className="form-control sv-input-field"
                        required="required"
                      />
                      <div className="sv-placeholder">
                        <span>Enter password</span>
                        <em>*</em>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="sv-check">
                      <input
                        type="checkbox"
                        id="bhourscheck2"
                        name="bhourscheck"
                      />
                      <label htmlFor="bhourscheck2">
                        I have read and agree to all{" "}
                        <Link to="/"> Terms &amp; conditions</Link>{" "}
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      name="submit"
                      className="sv-pb-lg w-100"
                    >
                      {" "}
                      <span>Join now</span>
                      <i className="icon-arrow-right" />
                    </button>
                  </div>
                  {/* <div className="sv-optioanl-or">
                    <span>OR</span>
                  </div>
                  <div className="form-group sv-sginup-btn">
                    <Link to="/" className="sv-pb btn-signup">
                      <img src="images/google.png" alt="images" />
                      Sign in with Google
                    </Link>
                  </div>
                  <div className="form-group sv-lost-password">
                    <Link to="/login">Sign In today</Link>
                    <a
                      href="lost-password.html"
                      className="sv-password-clr_light"
                    >
                      Lost password?
                    </a>
                  </div> */}
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      {/* login START */}
    </main>
  );
};

export default SignUp;
