import React from "react";
import { Route, Routes } from "react-router-dom";
import ProviderDashboard from "../components/ProviderComponents/ProviderDashboard";
import ProviderNotification from "../components/ProviderComponents/Notification/ProviderNotification";

const ProviderRoute = () => {
  return (
    <Routes>
      <Route path="dashboard" element={<ProviderDashboard />} />
      <Route path="notification" element={<ProviderNotification />} />
    </Routes>
  );
};

export default ProviderRoute;
