import React from "react";
import { Route, Routes } from "react-router-dom";
import ClientDashboard from "../components/ClientComponents/ClientDashboard";
import ClientNotification from "../components/ClientComponents/Notification/ClientNotification";
import PostAJob from "../pages/ClientPages/PostAJob";

const ClientRoute = () => {
  return (
    <Routes>
      <Route path="dashboard" element={<ClientDashboard />} />
      <Route path="notification" element={<ClientNotification />} />
      <Route path="postajob" element={<PostAJob />} />
    </Routes>
  );
};

export default ClientRoute;
