/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="sv-mainfooter">
        <div className="sv-footerbtm">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="sv-footerbtmtitle">
                  <h6>Copyright © All rights reserved. 2022</h6>
                </div>
              </div>
              <div className="col-lg-6">
                <ul className="sv-footetbtmlinks">
                  <li>
                    <a href="javascript:void(0);">Terms of service </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Privacy policy </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Content privacy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
