import React from "react";
import ProviderLayout from "../ProviderLayout/ProviderLayout";

const ProviderNotification = () => {
  return (
    <ProviderLayout>
      <main className="sv-main sv-bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-xl-9">
              <div className="row">
                <div className="col-12">
                  <div className="sv-boxwrapperv2">
                    <div className="sv-dbtitle">
                      <h3>
                        Notifications
                        <em className="sv-linknotification sv-linknotificationvtwo">
                          3 New
                        </em>
                      </h3>
                      <h5>Most recent</h5>
                    </div>
                    <div className="sv-bookingwrapper">
                      <div className="sv-notification-list">
                        <div className="sv-notification-title">
                          <span className="sv-unread-notification">
                            <i className="icon-bell"></i>
                          </span>
                          <p>
                            New appointment request from
                            <strong>“Allen S”</strong>
                            on
                            <strong>“Thu, 14 Oct 2022 @ 14:30 - 15:00”</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sv-bookingwrapper">
                      <div className="sv-notification-list">
                        <div className="sv-notification-title">
                          <span className="sv-unread-notification">
                            <i className="icon-bell"></i>
                          </span>
                          <p>
                            Your profile photo uploaded successfully on
                            <strong>“Wed, 11 Sep 2022</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sv-bookingwrapper">
                      <div className="sv-notification-list">
                        <div className="sv-notification-title">
                          <span className="sv-unread-notification">
                            <i className="icon-bell"></i>
                          </span>
                          <p>
                            You have declined the appointment of
                            <strong>“Joanna K”</strong>
                            on
                            <strong>“Fri, 26 Aug 2022 @ 12:00 - 12:30”</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sv-dbtitle">
                      <h5>Yesterday</h5>
                    </div>
                    <div className="sv-bookingwrapper">
                      <div className="sv-notification-list">
                        <div className="sv-notification-title">
                          <span>
                            <i className="icon-bell"></i>
                          </span>
                          <p>
                            New message received from
                            <strong>“Shawn M”</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sv-bookingwrapper">
                      <div className="sv-notification-list">
                        <div className="sv-notification-title">
                          <span>
                            <i className="icon-bell"></i>
                          </span>
                          <p>
                            You have declined the appointment of
                            <strong>“Monica Z”</strong>
                            on
                            <strong>“Sat, 12 Aug 2022 @ 09:00 - 09:30”</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sv-bookingwrapper">
                      <div className="sv-notification-list">
                        <div className="sv-notification-title">
                          <span>
                            <i className="icon-bell"></i>
                          </span>
                          <p>
                            New message received from
                            <strong>“Peterson J”</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sv-bookingwrapper">
                      <div className="sv-notification-list">
                        <div className="sv-notification-title">
                          <span>
                            <i className="icon-bell"></i>
                          </span>
                          <p>
                            New message received from
                            <strong>“Monica Z”</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sv-dbtitle">
                      <h5>Older notifications</h5>
                    </div>
                    <div className="sv-bookingwrapper">
                      <div className="sv-notification-list">
                        <div className="sv-notification-title">
                          <span>
                            <i className="icon-bell"></i>
                          </span>
                          <p>
                            New message received from
                            <strong>“Shemus R”</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sv-bookingwrapper">
                      <div className="sv-notification-list">
                        <div className="sv-notification-title">
                          <span>
                            <i className="icon-bell"></i>
                          </span>
                          <p>
                            You have declined the appointment of
                            <strong>“Sherline T”</strong>
                            on
                            <strong>“Mon, 10 Jul 2022 @ 15:00 - 15:30”</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sv-bookingwrapper">
                      <div className="sv-notification-list">
                        <div className="sv-notification-title">
                          <span>
                            <i className="icon-bell"></i>
                          </span>
                          <p>
                            New appointment request from
                            <strong>“Sherline T”</strong>
                            on
                            <strong>“Mon, 10 Jul 2022 @ 15:00 - 15:30”</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sv-pagination">
                    <ul>
                      <li>
                        <a href="db-notifications.html">
                          <i className="icon-chevron-left"></i>
                        </a>
                      </li>
                      <li>
                        <a href="db-notifications.html">1</a>
                      </li>
                      <li>
                        <a href="db-notifications.html">2</a>
                      </li>
                      <li>
                        <a href="db-notifications.html">3</a>
                      </li>
                      <li className="active">
                        <a href="db-notifications.html">4</a>
                      </li>
                      <li>
                        <a href="db-notifications.html">...</a>
                      </li>
                      <li>
                        <a href="db-notifications.html">60</a>
                      </li>
                      <li>
                        <a href="db-notifications.html">
                          <i className="icon-chevron-right"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </ProviderLayout>
  );
};

export default ProviderNotification;
