import React from "react";
import HomeListing from "../components/Home/HomeListing";
import MobileApp from "../components/Home/MobileApp";
import Layout from "../components/Layout/Layout";
import CardListing from "../components/Home/CardListing";

const HomePage = () => {
  return (
    <>
      <Layout>
        <MobileApp />
        <HomeListing />
        <CardListing />
      </Layout>
    </>
  );
};

export default HomePage;
