import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ClientRoute from "./routes/ClientRoute";
import HomeRoute from "./routes/HomeRoute";
import ProviderRoute from "./routes/ProviderRoute";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Notifcation from "./pages/ProviderPages/Notifcation";
import JobDetail from "./components/ClientComponents/JobDetail";
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<HomeRoute />} />
        <Route path="/client/*" element={<ClientRoute />} />
        <Route path="/provider/*" element={<ProviderRoute />} />
        <Route path="/notification" element={<Notifcation />} />
        <Route path="/JobDetail" element={<JobDetail />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
