import React from "react";
import ClientSidebar from "./ClientSidebar";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";

const ClientLayout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="container-fluid sv-bg-light">
        <div className="row">
          <div className="col-md-3">
            <ClientSidebar />
          </div>
          <div className="col-md-9">{children}</div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ClientLayout;
