/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const AppointmentModal = () => {
  return (
    <div className="modal fade" id="popup12" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5>Add/edit appointment details</h5>
            <a
              href="javascript:void(0);"
              className="sv-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="icon-x"></i>
            </a>
          </div>
          <div className="modal-body">
            <form className="sv-themeform">
              <fieldset>
                <div className="sv-themeform__wrap">
                  <div className="sv-tab">
                    <div className="form-group">
                      <ul className="nav nav-tabs" id="myTab1" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="slots1-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#slots1"
                            type="button"
                            role="tab"
                            aria-controls="slots1"
                            aria-selected="true"
                          >
                            <i className="icon-clock"></i> Add time slots
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="days1-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#days1"
                            type="button"
                            role="tab"
                            aria-controls="days1"
                            aria-selected="false"
                          >
                            <i className="icon-lock"></i> Unavailable days
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content" id="nav-tabContent1">
                      <div
                        className="tab-pane fade show active"
                        id="slots1"
                        role="tabpanel"
                      >
                        <div className="form-group">
                          <a href="javascript:void(0);" className="sv-newslots">
                            <i className="icon-plus"></i> Add new time slots
                            <svg>
                              <rect width="100%" height="100%"></rect>
                            </svg>
                          </a>
                        </div>
                        <div id="time_accordion" className="form-group">
                          <div className="sv-formarea">
                            <div
                              className="sv-formarea_title"
                              role="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#time1"
                              aria-expanded="true"
                            >
                              <h5>Monday</h5>
                            </div>
                            <div
                              id="time1"
                              className="collapse show"
                              data-bs-parent="#time_accordion"
                            >
                              <div className="sv-formarea_content">
                                <div className="sv-formarea_group">
                                  <div className="form-group">
                                    <ul className="sv-formarea_list">
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>9:00 - 9:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>9:30 - 10:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>10:00 - 10:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>10:30 - 11:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>11:00 - 11:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>11:30 - 12:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>12:00 - 12:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>12:30 - 13:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>13:00 - 13:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>13:30 - 14:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>14:00 - 14:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>14:30 - 15:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>15:00 - 15:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>15:30 - 16:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>16:00 - 16:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>16:30 - 17:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>17:00 - 17:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>17:30 - 18:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>18:00 - 18:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>18:30 - 19:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="form-group sv-formbtn">
                                    <a
                                      href="javascript:void(0);"
                                      className="sv-pb-lg sv-redbtn"
                                    >
                                      <span>Remove this time slot</span>
                                      <i className="icon-trash-2"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sv-formarea">
                            <div
                              className="sv-formarea_title"
                              role="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#time2"
                              aria-expanded="false"
                            >
                              <h5>Tuesday</h5>
                            </div>
                            <div
                              id="time2"
                              className="collapse"
                              data-bs-parent="#time_accordion"
                            >
                              <div className="sv-formarea_content">
                                <div className="sv-formarea_group">
                                  <div className="form-group">
                                    <ul className="sv-formarea_list">
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>9:00 - 9:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>9:30 - 10:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>10:00 - 10:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>10:30 - 11:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>11:00 - 11:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>11:30 - 12:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>12:00 - 12:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>12:30 - 13:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>13:00 - 13:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>13:30 - 14:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>14:00 - 14:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>14:30 - 15:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>15:00 - 15:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>15:30 - 16:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>16:00 - 16:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>16:30 - 17:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>17:00 - 17:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>17:30 - 18:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>18:00 - 18:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>18:30 - 19:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="form-group sv-formbtn">
                                    <a
                                      href="javascript:void(0);"
                                      className="sv-pb-lg sv-redbtn"
                                    >
                                      <span>Remove this time slot</span>
                                      <i className="icon-trash-2"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sv-formarea">
                            <div
                              className="sv-formarea_title"
                              role="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#time3"
                              aria-expanded="false"
                            >
                              <h5>Wednesday</h5>
                            </div>
                            <div
                              id="time3"
                              className="collapse"
                              data-bs-parent="#time_accordion"
                            >
                              <div className="sv-formarea_content">
                                <div className="sv-formarea_group">
                                  <div className="form-group">
                                    <ul className="sv-formarea_list">
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>9:00 - 9:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>9:30 - 10:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>10:00 - 10:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>10:30 - 11:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>11:00 - 11:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>11:30 - 12:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>12:00 - 12:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>12:30 - 13:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>13:00 - 13:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>13:30 - 14:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>14:00 - 14:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>14:30 - 15:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>15:00 - 15:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>15:30 - 16:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>16:00 - 16:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>16:30 - 17:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>17:00 - 17:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>17:30 - 18:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>18:00 - 18:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>18:30 - 19:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="form-group sv-formbtn">
                                    <a
                                      href="javascript:void(0);"
                                      className="sv-pb-lg sv-redbtn"
                                    >
                                      <span>Remove this time slot</span>
                                      <i className="icon-trash-2"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sv-formarea">
                            <div
                              className="sv-formarea_title"
                              role="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#time4"
                              aria-expanded="false"
                            >
                              <h5>Thursday</h5>
                            </div>
                            <div
                              id="time4"
                              className="collapse"
                              data-bs-parent="#time_accordion"
                            >
                              <div className="sv-formarea_content">
                                <div className="sv-formarea_group">
                                  <div className="form-group">
                                    <ul className="sv-formarea_list">
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>9:00 - 9:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>9:30 - 10:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>10:00 - 10:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>10:30 - 11:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>11:00 - 11:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>11:30 - 12:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>12:00 - 12:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>12:30 - 13:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>13:00 - 13:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>13:30 - 14:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>14:00 - 14:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>14:30 - 15:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>15:00 - 15:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>15:30 - 16:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>16:00 - 16:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>16:30 - 17:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>17:00 - 17:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>17:30 - 18:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>18:00 - 18:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>18:30 - 19:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="form-group sv-formbtn">
                                    <a
                                      href="javascript:void(0);"
                                      className="sv-pb-lg sv-redbtn"
                                    >
                                      <span>Remove this time slot</span>
                                      <i className="icon-trash-2"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sv-formarea">
                            <div
                              className="sv-formarea_title"
                              role="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#time5"
                              aria-expanded="false"
                            >
                              <h5>Friday</h5>
                            </div>
                            <div
                              id="time5"
                              className="collapse"
                              data-bs-parent="#time_accordion"
                            >
                              <div className="sv-formarea_content">
                                <div className="sv-formarea_group">
                                  <div className="form-group">
                                    <ul className="sv-formarea_list">
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>9:00 - 9:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>9:30 - 10:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>10:00 - 10:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>10:30 - 11:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>11:00 - 11:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>11:30 - 12:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>12:00 - 12:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>12:30 - 13:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>13:00 - 13:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>13:30 - 14:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>14:00 - 14:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>14:30 - 15:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>15:00 - 15:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>15:30 - 16:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>16:00 - 16:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>16:30 - 17:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>17:00 - 17:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>17:30 - 18:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>18:00 - 18:30</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <h6>18:30 - 19:00</h6>
                                          <span>2 Slots</span>
                                          <i className="icon-trash-2"></i>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="form-group sv-formbtn">
                                    <a
                                      href="javascript:void(0);"
                                      className="sv-pb-lg sv-redbtn"
                                    >
                                      <span>Remove this time slot</span>
                                      <i className="icon-trash-2"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group sv-formbtn">
                          <a href="javascript:void(0);" className="sv-pb-lg">
                            Save & update changes
                          </a>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="days1" role="tabpanel">
                        <div className="form-group">
                          <a href="javascript:void(0);" className="sv-newslots">
                            <i className="icon-plus"></i> Add new time slots
                            <svg>
                              <rect width="100%" height="100%"></rect>
                            </svg>
                          </a>
                        </div>
                        <div className="form-group">
                          <ul className="sv-undayslist">
                            <li>
                              <div className="sv-undayslist_content">
                                <div className="sv-undayslist_title">
                                  <h6>Unavailable on</h6>
                                  <h5>
                                    <b>Wednesday - May 21, 2022</b>
                                    <span className="sv-tag">
                                      <em>Upcoming</em>
                                    </span>
                                  </h5>
                                </div>
                                <a href="javascript:void(0);">
                                  <i className="icon-trash-2"></i>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div className="sv-undayslist_content">
                                <div className="sv-undayslist_title">
                                  <h6>Unavailable on</h6>
                                  <h5>
                                    <b>Sunday - May 21, 2022</b>
                                    <span className="sv-tag">
                                      <em className="sv-expired">Expired</em>
                                    </span>
                                  </h5>
                                </div>
                                <a href="javascript:void(0);">
                                  <i className="icon-trash-2"></i>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div className="sv-undayslist_content">
                                <div className="sv-undayslist_title">
                                  <h6>Unavailable on</h6>
                                  <h5>
                                    <b>July 27 - August 10, 2022</b>
                                    <span className="sv-tag">
                                      <em className="sv-expired">Expired</em>
                                    </span>
                                  </h5>
                                </div>
                                <a href="javascript:void(0);">
                                  <i className="icon-trash-2"></i>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="form-group sv-formbtn">
                          <a href="javascript:void(0);" className="sv-pb-lg">
                            Save & update changes
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentModal;
