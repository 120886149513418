/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from "react";
import Layout from "../Layout/Layout";
import AppointmentModal from "./AppointmentModal";

const CardDetails = () => {
  return (
    <>
      <Layout>
        <main className="sv-main sv-bg-light">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="sv-freelancdetail">
                  <div className="sv-bookappoint">
                    <figure>
                      <img
                        src="/images/freelancerdetail/img-01.jpg"
                        alt="image-descp"
                      />
                    </figure>
                    <div className="sv-btnarea sv-sm-btn">
                      <a
                        href="javascript:void(0);"
                        className="sv-pb "
                        data-bs-toggle="modal"
                        data-bs-target="#popup12"
                      >
                        {" "}
                        <span>Book an appointment</span>
                        <i className="icon-calendar" />
                      </a>
                      <a
                        href="javascript:void(0);"
                        className="sv-sb sv-btngray"
                      >
                        Let’s talk now{" "}
                      </a>
                    </div>
                  </div>
                  <div className="sv-freedetailwrap">
                    <div className="sv-freelancerinfo">
                      <figure>
                        <img
                          src="/images/freelancerdetail/img-01.jpg"
                          alt="/images"
                        />
                      </figure>
                      <div className="sv-freelancerdetail">
                        <h3>
                          Petterson S{" "}
                          <i
                            className="icon-zap sv-icongreen"
                            data-tippy-trigger="mouseenter"
                            data-tippy-html="#sv-trending"
                            data-tippy-interactive="true"
                            data-tippy-placement="top"
                          />
                          <i
                            className="icon-check-circle sv-linkblue"
                            data-tippy-trigger="mouseenter"
                            data-tippy-html="#sv-verifed"
                            data-tippy-interactive="true"
                            data-tippy-placement="top"
                          />
                        </h3>
                        <h5>Technology making thats all I do</h5>
                        <h4>
                          <i className="icon-map-pin" />
                          1463 Middle Byway, Mesa, CO 47393
                        </h4>
                      </div>
                      <div className="sv-featureRating">
                        <span className="sv-featureRating__stars">
                          <span />
                        </span>
                        <div className="sv-totalreview">
                          <span>
                            4.5/<em>5.0</em>
                            <em>User review</em>
                          </span>
                        </div>
                        <div className="sv-iconheart">
                          <i className="icon-heart" />
                          <span>Add to save</span>
                        </div>
                      </div>
                    </div>
                    <div className="sv-detailwrapper">
                      <div className="sv-detailitem">
                        <h6>Languages I know</h6>
                        <div className="sv-languagelist">
                          <ul className="sv-languages">
                            <li>English</li>
                            <li>Arabic</li>
                            <li>Chinese</li>
                            <li>Hebrew</li>
                            <li>French</li>
                            <li>Spanish</li>
                            <li>
                              <a
                                className="sv-showmore"
                                href="javascript:void(0);"
                                data-tippy-trigger="click"
                                data-tippy-html="#sv-industrypro"
                                data-tippy-interactive="true"
                                data-tippy-placement="top-start"
                              >
                                +02 more
                              </a>
                              <div
                                id="sv-industrypro"
                                className="sv-tippytooltip d-none"
                              >
                                <div className="sv-selecttagtippy">
                                  <ul className="sv-posttag">
                                    <li>
                                      <a href="provider-details.html">German</a>
                                    </li>
                                    <li>
                                      <a href="provider-details.html">
                                        Portuguese
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="sv-detailitem sv-detailitemvtwo">
                        <h6>Contact details</h6>
                        <ul className="sv-contactdetail">
                          <li>
                            <em className="icon-phone-call" /> <i>0800 - 28</i>
                            <span>*** - ***</span>
                          </li>
                          <li>
                            <em className="icon-monitor" />{" "}
                            <a href="provider-details.html">
                              www.cindylorex77.com
                            </a>
                          </li>
                          <li>
                            <em className="icon-mail" /> <i>cindy287@</i>
                            <span>*****</span>
                            <i>.com</i>
                          </li>
                          <li>
                            <em className="icon-printer" /> <i>1324 - 14</i>
                            <span>*** - ***</span>
                          </li>
                          <li>
                            <em className="fab fa-skype" /> <i>cindylore</i>
                            <span>********</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="sv-btnarea sv-btnarea-two sv-sm-btn">
                    <a
                      href="javascript:void(0);"
                      className="sv-pb "
                      data-bs-toggle="modal"
                      data-bs-target="#popup12"
                    >
                      {" "}
                      <span>Book an appointment</span>
                      <i className="icon-calendar" />
                    </a>
                    <a href="javascript:void(0);" className="sv-sb sv-btngray">
                      Let’s talk now{" "}
                    </a>
                  </div>
                </div>
                {/* <div className="sv-detailstabs">
                  <ul
                    className="nav nav-tabs sv-nav-tabs"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        <i className="icon-home" />
                        <span>Introduction</span>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        <i className="icon-users" />
                        <span>Services &amp; team</span>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#contact"
                        type="button"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                      >
                        <i className="icon-briefcase" />
                        <span>Education &amp; experience</span>
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content sv-tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="sv-tabswrapper">
                        <div className="sv-tabstitle">
                          <h3>A brief introduction</h3>
                        </div>
                        <div className="sv-description">
                          <p>
                            On the other hand, we denounce with righteous
                            indignation and dislike men who are so beguiled and
                            demoralized by the charms of pleasure of the moment,
                            so blinded by desire, that they cannot foresee the
                            pain and trouble that are bound to ensue; and equal
                            blame belongs to those who fail in their duty
                            through weakness of will, which is the same as
                            saying through shrinking from toil and pain. These
                            cases are perfectly simple and easy to distinguish.
                            In a free hour, when our power of choice is
                            untrammelled and when nothing prevents our being
                            able to do what we like best, every pleasure is to
                            be welcomed and every pain avoided. But in certain
                            circumstances and owing to the claims of duty or the
                            obligations of business it will frequently occur
                            that pleasures have to be repudiated and annoyances
                            accepted. The wise man therefore always holds in
                            these matters to this principle of selection.
                          </p>
                        </div>
                        <ul className="sv-mainlist">
                          <li>
                            Accusantium doloremque laudantium totam rem aperiam.
                          </li>
                          <li>
                            Eicta sunt explicaboemo enim ipsam voluptatemuia
                          </li>
                          <li>Voluptas sit aspernatur aut odit aut fugited</li>
                          <li>
                            Quia consequuntur magni dolores eos qui ratione
                          </li>
                        </ul>
                        <div className="sv-description">
                          <p>
                            He rejects pleasures to secure other greater
                            pleasures, or else he endures pains to avoid worse
                            pains. On the other hand, we denounce with righteous
                            indignation and dislike men who are so beguiled and
                            demoralized by the charms of pleasure of the moment,
                            so blinded by desire, that they cannot foresee the
                            pain and trouble that are bound to ensue; and equal
                            blame belongs to those who fail in their duty
                            through weakness of will, which is the same as
                            saying through shrinking from toil and pain. These
                            cases are perfectly simple and easy to distinguish.
                            In a free hour
                          </p>
                          <p>
                            When our power of choice is untrammelled and when
                            nothing prevents our being able to do what we like
                            best, every pleasure is to be welcomed and every
                            pain avoided. But in certain circumstances and owing
                            to the claims of duty or the obligations of business
                            it will frequently occur that pleasures have to be
                            repudiated and annoyances accepted. The wise man
                            therefore always holds in these matters to this
                            principle of selection: he rejects pleasures to
                            secure other greater pleasures, or else he endures
                            pains to avoid worse pains.
                          </p>
                        </div>
                      </div>
                      <div className="sv-tabswrapper">
                        <div className="sv-tabstitle">
                          <h3>Our business hours</h3>
                        </div>
                        <div className="sv-description">
                          <p>
                            Dignissimos ducimus qui blanditiis praesentium
                            voluptatum deleniti atque corrupti quosolores et
                            quas molestias excepturi sint occaecati cupiditate
                            non providente similique sunt in culpa quiteofficia
                            deserunt mollitia animi idames est laborum etnale
                            dolorum fuga rerum faciliste.
                          </p>
                        </div>
                        <ul className="sv-bussinessdays">
                          <li>
                            <div className="sv-busnissitem active">
                              <h6>Monday</h6>
                              <div className="sv-hourstimmig">
                                <span>
                                  09:00 AM - 02:00 PM <i>Lunch break</i>
                                </span>
                                <span>03:00 PM - 07:00 PM</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="sv-busnissitem">
                              <h6>Tuesday</h6>
                              <div className="sv-hourstimmig">
                                <span>
                                  09:00 AM - 02:00 PM <i>Lunch break</i>
                                </span>
                                <span>03:00 PM - 07:00 PM </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="sv-busnissitem">
                              <h6>Wednesday</h6>
                              <div className="sv-hourstimmig">
                                <span>
                                  09:00 AM - 02:00 PM <i>Lunch break</i>
                                </span>
                                <span>03:00 PM - 07:00 PM </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="sv-busnissitem">
                              <h6>Thursday</h6>
                              <div className="sv-hourstimmig">
                                <span>
                                  09:00 AM - 02:00 PM <i>Lunch break</i>
                                </span>
                                <span>03:00 PM - 07:00 PM </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="sv-busnissitem">
                              <h6>Thursday</h6>
                              <div className="sv-hourstimmig">
                                <span>09:00 AM - 02:00 PM</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="sv-busnissitem">
                              <h6>Saturaday</h6>
                              <div className="sv-dayoff">
                                <span>Day off</span>
                                <h5>For emergency appointment call</h5>
                                <h6>
                                  {" "}
                                  0800 - 28 <span>*** - ***</span>{" "}
                                </h6>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="sv-busnissitem">
                              <h6>Sunday</h6>
                              <div className="sv-dayoff">
                                <span>Day off</span>
                                <h4>No service available today</h4>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </main>
        <AppointmentModal />
      </Layout>
    </>
  );
};

export default CardDetails;
