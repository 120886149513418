import React from "react";
import { Link } from "react-router-dom";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const CardListing = () => {
  return (
    <>
      {/* SERVICES START */}
      <section className="sv-main-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sv-main-title-holder">
                <div className="sv-maintitle mt-5">
                  <h2>Job Postings</h2>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="sv-trendingserviceslider sv-sliderarrow">
                <Swiper
                  slidesPerView={3}
                  spaceBetween={30}
                  loop
                  grabCursor
                  autoplay={{ delay: 2500, disableOnInteraction: false }}
                  navigation={{
                    nextEl: ".splide__arrow--next",
                    prevEl: ".splide__arrow--prev",
                  }}
                  modules={[Navigation, Autoplay]}
                >
                  <SwiperSlide>
                    <div className="sv-trendingserviceslider_content">
                      <figure className="sv-trendingserviceslider_img">
                        <Link to={`/card/details/${1}`}>
                          <img src="/images/avatar.png" alt="img-description" />
                        </Link>
                      </figure>
                      <div className="sv-trendingserviceslider_title">
                        <h4>
                          <Link to={`/card/details/${1}`}>Jullian H</Link>
                          <i
                            className="icon-zap sv-icongreen"
                            data-tippy-trigger="mouseenter"
                            data-tippy-html="#sv-trending"
                            data-tippy-interactive="true"
                            data-tippy-placement="top"
                          />
                          <i
                            className="icon-check-circle sv-linkblue"
                            data-tippy-trigger="mouseenter"
                            data-tippy-html="#sv-verifed"
                            data-tippy-interactive="true"
                            data-tippy-placement="top"
                          />
                        </h4>
                        <p>Fumigator/Pest control</p>
                        <div className="sv-featureRating">
                          <span className="sv-featureRating__stars">
                            <span />
                          </span>
                          <h6>
                            4.5 <em>/5.0</em>
                          </h6>
                          <em>User review</em>
                        </div>
                        <address>
                          <i className="icon-map-pin" />
                          8 Medio Oriente Valparaiso
                        </address>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <div className="sv-trendingserviceslider_content">
                      <figure className="sv-trendingserviceslider_img">
                        <Link to={`/card/details/${1}`}>
                          <img src="/images/avatar.png" alt="img-description" />
                        </Link>
                      </figure>
                      <div className="sv-trendingserviceslider_title">
                        <h4>
                          <Link to={`/card/details/${1}`}>Jullian H</Link>
                          <i
                            className="icon-zap sv-icongreen"
                            data-tippy-trigger="mouseenter"
                            data-tippy-html="#sv-trending"
                            data-tippy-interactive="true"
                            data-tippy-placement="top"
                          />
                          <i
                            className="icon-check-circle sv-linkblue"
                            data-tippy-trigger="mouseenter"
                            data-tippy-html="#sv-verifed"
                            data-tippy-interactive="true"
                            data-tippy-placement="top"
                          />
                        </h4>
                        <p>Web designer/Web Developer</p>
                        <div className="sv-featureRating">
                          <span className="sv-featureRating__stars">
                            <span />
                          </span>
                          <h6>
                            4.5 <em>/5.0</em>
                          </h6>
                          <em>User review</em>
                        </div>
                        <address>
                          <i className="icon-map-pin" />
                          730 Burning Vale, Long Beach, MD 36855
                        </address>
                      </div>
                    </div>
                  </SwiperSlide>
                  {/* <SwiperSlide>
                    <div className="sv-trendingserviceslider_content">
                      <figure className="sv-trendingserviceslider_img">
                        <Link to={`/card/details/${1}`}>
                          <img
                            src="/images/indexv1/slider/img-03.jpg"
                            alt="img-description"
                          />
                        </Link>
                      </figure>
                      <div className="sv-trendingserviceslider_title">
                        <h4>
                          <Link to={`/card/details/${1}`}>Petterson S</Link>
                          <i
                            className="icon-zap sv-icongreen"
                            data-tippy-trigger="mouseenter"
                            data-tippy-html="#sv-trending"
                            data-tippy-interactive="true"
                            data-tippy-placement="top"
                          />
                          <i
                            className="icon-check-circle sv-linkblue"
                            data-tippy-trigger="mouseenter"
                            data-tippy-html="#sv-verifed"
                            data-tippy-interactive="true"
                            data-tippy-placement="top"
                          />
                        </h4>
                        <p>Creating idea into true reality for everyone</p>
                        <div className="sv-featureRating">
                          <span className="sv-featureRating__stars">
                            <span />
                          </span>
                          <h6>
                            4.5 <em>/5.0</em>
                          </h6>
                          <em>User review</em>
                        </div>
                        <address>
                          <i className="icon-map-pin" />
                          730 Burning Vale, Long Beach, MD 36855
                        </address>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="sv-trendingserviceslider_content">
                      <figure className="sv-trendingserviceslider_img">
                        <Link to={`/card/details/${1}`}>
                          <img
                            src="/images/indexv1/slider/img-02.jpg"
                            alt="img-description"
                          />
                        </Link>
                      </figure>
                      <div className="sv-trendingserviceslider_title">
                        <h4>
                          <Link to={`/card/details/${1}`}>Jullian H</Link>
                          <i
                            className="icon-zap sv-icongreen"
                            data-tippy-trigger="mouseenter"
                            data-tippy-html="#sv-trending"
                            data-tippy-interactive="true"
                            data-tippy-placement="top"
                          />
                          <i
                            className="icon-check-circle sv-linkblue"
                            data-tippy-trigger="mouseenter"
                            data-tippy-html="#sv-verifed"
                            data-tippy-interactive="true"
                            data-tippy-placement="top"
                          />
                        </h4>
                        <p>Cleaning and maintaining housekeeping</p>
                        <div className="sv-featureRating">
                          <span className="sv-featureRating__stars">
                            <span />
                          </span>
                          <h6>
                            4.5 <em>/5.0</em>
                          </h6>
                          <em>User review</em>
                        </div>
                        <address>
                          <i className="icon-map-pin" />
                          730 Burning Vale, Long Beach, MD 36855
                        </address>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <div className="sv-trendingserviceslider_content">
                      <figure className="sv-trendingserviceslider_img">
                        <Link to={`/card/details/${1}`}>
                          <img
                            src="/images/indexv1/slider/img-02.jpg"
                            alt="img-description"
                          />
                        </Link>
                      </figure>
                      <div className="sv-trendingserviceslider_title">
                        <h4>
                          <Link to={`/card/details/${1}`}>Jullian H</Link>
                          <i
                            className="icon-zap sv-icongreen"
                            data-tippy-trigger="mouseenter"
                            data-tippy-html="#sv-trending"
                            data-tippy-interactive="true"
                            data-tippy-placement="top"
                          />
                          <i
                            className="icon-check-circle sv-linkblue"
                            data-tippy-trigger="mouseenter"
                            data-tippy-html="#sv-verifed"
                            data-tippy-interactive="true"
                            data-tippy-placement="top"
                          />
                        </h4>
                        <p>Cleaning and maintaining housekeeping</p>
                        <div className="sv-featureRating">
                          <span className="sv-featureRating__stars">
                            <span />
                          </span>
                          <h6>
                            4.5 <em>/5.0</em>
                          </h6>
                          <em>User review</em>
                        </div>
                        <address>
                          <i className="icon-map-pin" />
                          730 Burning Vale, Long Beach, MD 36855
                        </address>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="sv-trendingserviceslider_content">
                      <figure className="sv-trendingserviceslider_img">
                        <Link to={`/card/details/${1}`}>
                          <img
                            src="/images/indexv1/slider/img-03.jpg"
                            alt="img-description"
                          />
                        </Link>
                      </figure>
                      <div className="sv-trendingserviceslider_title">
                        <h4>
                          <Link to={`/card/details/${1}`}>Petterson S</Link>
                          <i
                            className="icon-zap sv-icongreen"
                            data-tippy-trigger="mouseenter"
                            data-tippy-html="#sv-trending"
                            data-tippy-interactive="true"
                            data-tippy-placement="top"
                          />
                          <i
                            className="icon-check-circle sv-linkblue"
                            data-tippy-trigger="mouseenter"
                            data-tippy-html="#sv-verifed"
                            data-tippy-interactive="true"
                            data-tippy-placement="top"
                          />
                        </h4>
                        <p>Creating idea into true reality for everyone</p>
                        <div className="sv-featureRating">
                          <span className="sv-featureRating__stars">
                            <span />
                          </span>
                          <h6>
                            4.5 <em>/5.0</em>
                          </h6>
                          <em>User review</em>
                        </div>
                        <address>
                          <i className="icon-map-pin" />
                          730 Burning Vale, Long Beach, MD 36855
                        </address>
                      </div>
                    </div>
                  </SwiperSlide> */}
                </Swiper>
                <button
                  className="splide__arrow splide__arrow--next"
                  type="button"
                  aria-controls="sv-trendingserviceslider-track"
                  aria-label="Next slide"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 40"
                    width="40"
                    height="40"
                  >
                    <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path>
                  </svg>
                </button>
                <button
                  className="splide__arrow splide__arrow--prev"
                  type="button"
                  aria-controls="sv-trendingserviceslider-track"
                  aria-label="Go to last slide"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 40"
                    width="40"
                    height="40"
                  >
                    <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* SERVICES END */}
    </>
  );
};

export default CardListing;
