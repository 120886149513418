/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url */
import React from "react";
import Layout from "../Layout/Layout";

const JobDetail = () => {
  return (
    <Layout>
      <div className="container">
        <div className=" sv-detailstabs">
          {/* <ul className="nav nav-tabs sv-nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              <i className="icon-home" />
              <span>Introduction</span>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              <i className="icon-users" />
              <span>Services &amp; team</span>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#contact"
              type="button"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
            >
              <i className="icon-briefcase" />
              <span>Education &amp; experience</span>
            </button>
          </li>
        </ul> */}
          <div className="tab-content sv-tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="sv-tabswrapper">
                <div className="sv-tabstitle">
                  <h3> Job details</h3>
                </div>
                <div className="sv-description">
                  <p>
                    On the other hand, we denounce with righteous indignation
                    and dislike men who are so beguiled and demoralized by the
                    charms of pleasure of the moment, so blinded by desire, that
                    they cannot foresee the pain and trouble that are bound to
                    ensue; and equal blame belongs to those who fail in their
                    duty through weakness of will, which is the same as saying
                    through shrinking from toil and pain. These cases are
                    perfectly simple and easy to distinguish. In a free hour,
                    when our power of choice is untrammelled and when nothing
                    prevents our being able to do what we like best, every
                    pleasure is to be welcomed and every pain avoided. But in
                    certain circumstances and owing to the claims of duty or the
                    obligations of business it will frequently occur that
                    pleasures have to be repudiated and annoyances accepted. The
                    wise man therefore always holds in these matters to this
                    principle of selection.
                  </p>
                </div>
                <ul className="sv-mainlist">
                  <li>Accusantium doloremque laudantium totam rem aperiam.</li>
                  <li>Eicta sunt explicaboemo enim ipsam voluptatemuia</li>
                  <li>Voluptas sit aspernatur aut odit aut fugited</li>
                  <li>Quia consequuntur magni dolores eos qui ratione</li>
                </ul>
                <div className="sv-description">
                  <p>
                    He rejects pleasures to secure other greater pleasures, or
                    else he endures pains to avoid worse pains. On the other
                    hand, we denounce with righteous indignation and dislike men
                    who are so beguiled and demoralized by the charms of
                    pleasure of the moment, so blinded by desire, that they
                    cannot foresee the pain and trouble that are bound to ensue;
                    and equal blame belongs to those who fail in their duty
                    through weakness of will, which is the same as saying
                    through shrinking from toil and pain. These cases are
                    perfectly simple and easy to distinguish. In a free hour
                  </p>
                  <p>
                    When our power of choice is untrammelled and when nothing
                    prevents our being able to do what we like best, every
                    pleasure is to be welcomed and every pain avoided. But in
                    certain circumstances and owing to the claims of duty or the
                    obligations of business it will frequently occur that
                    pleasures have to be repudiated and annoyances accepted. The
                    wise man therefore always holds in these matters to this
                    principle of selection: he rejects pleasures to secure other
                    greater pleasures, or else he endures pains to avoid worse
                    pains.
                  </p>
                </div>
              </div>
              <div className="sv-tabswrapper">
                <div className="sv-tabstitle">
                  <h3>Our business hours</h3>
                </div>
                <div className="sv-description">
                  <p>
                    Dignissimos ducimus qui blanditiis praesentium voluptatum
                    deleniti atque corrupti quosolores et quas molestias
                    excepturi sint occaecati cupiditate non providente similique
                    sunt in culpa quiteofficia deserunt mollitia animi idames
                    est laborum etnale dolorum fuga rerum faciliste.
                  </p>
                </div>
                <ul className="sv-bussinessdays">
                  <li>
                    <div className="sv-busnissitem active">
                      <h6>Monday</h6>
                      <div className="sv-hourstimmig">
                        <span>
                          09:00 AM - 02:00 PM <i>Lunch break</i>
                        </span>
                        <span>03:00 PM - 07:00 PM</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="sv-busnissitem">
                      <h6>Tuesday</h6>
                      <div className="sv-hourstimmig">
                        <span>
                          09:00 AM - 02:00 PM <i>Lunch break</i>
                        </span>
                        <span>03:00 PM - 07:00 PM </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="sv-busnissitem">
                      <h6>Wednesday</h6>
                      <div className="sv-hourstimmig">
                        <span>
                          09:00 AM - 02:00 PM <i>Lunch break</i>
                        </span>
                        <span>03:00 PM - 07:00 PM </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="sv-busnissitem">
                      <h6>Thursday</h6>
                      <div className="sv-hourstimmig">
                        <span>
                          09:00 AM - 02:00 PM <i>Lunch break</i>
                        </span>
                        <span>03:00 PM - 07:00 PM </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="sv-busnissitem">
                      <h6>Thursday</h6>
                      <div className="sv-hourstimmig">
                        <span>09:00 AM - 02:00 PM</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="sv-busnissitem">
                      <h6>Saturaday</h6>
                      <div className="sv-dayoff">
                        <span>Day off</span>
                        <h5>For emergency appointment call</h5>
                        <h6>
                          {" "}
                          0800 - 28 <span>*** - ***</span>{" "}
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="sv-busnissitem">
                      <h6>Sunday</h6>
                      <div className="sv-dayoff">
                        <span>Day off</span>
                        <h4>No service available today</h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default JobDetail;
