import React from "react";
import { Link } from "react-router-dom";

const ProviderSidebar = () => {
  return (
    <>
      <main className="sv-main sv-bg-light">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-12 col-xl-12 d-none d-lg-block">
              <div className="sv-dbsidenav">
                <ul className="sv-dbnavlist">
                  <li className="active">
                    {" "}
                    <Link to="#">
                      {" "}
                      <i className="icon-home" /> Display Name
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="#">
                      {" "}
                      <i className="icon-folder" /> Search for jobs
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="#">
                      {" "}
                      <i className="icon-message-square" /> Terms and conditions
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/provider/notification">
                      {" "}
                      <i className="icon-user" /> Notifications
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="#">
                      {" "}
                      <i className="icon-package" /> Payment
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="db-pricing-plan.html">
                      {" "}
                      <i className="icon-settings" /> Delete Account
                    </Link>
                  </li>
                  <li className="sv-poweroff">
                    {" "}
                    <Link to="index.html">
                      {" "}
                      <i className="icon-power" /> Logout
                    </Link>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ProviderSidebar;
