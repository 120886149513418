/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url */
import React, { useState } from "react";
import ClientLayout from "./ClientLayout/ClientLayout";

const ClientPostaJob = () => {
  const [picture, setPicture] = useState(null);

  const handlePictureUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPicture(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleRemovePicture = () => {
    setPicture(null);
  };

  return (
    <>
      <ClientLayout>
        {/* MAIN START */}
        <main>
          {/* login START */}
          <div className="sv-loginconatiner sv-signup">
            <div className="sv-popupcontainer">
              <div className="sv-login_title p-0">
                <img src="/images/logonav.png" alt="/images description" />
              </div>
              <div className="sv-login-content">
                <form className="sv-themeform">
                  <fieldset>
                    <div className="sv-themeform__wrap">
                      <div class="form-group">
                        <label class="sv-label">Upload Image</label>
                        <div class="sv-uploadphoto">
                          {picture ? (
                            <>
                              <img
                                class="sv-border"
                                src={picture}
                                alt="image"
                              />
                              <a
                                href="javascript:void(0);"
                                class="sv-tpbtn"
                                onClick={handleRemovePicture}
                              >
                                <i class="icon-trash-2"></i>
                                <span>Remove photo</span>
                              </a>
                            </>
                          ) : (
                            <>
                              <h5 className="cursor-pointer">
                                <input
                                  type="file"
                                  onChange={handlePictureUpload}
                                  id="file1"
                                />
                                <label for="file1">click here</label> to upload
                                photo
                              </h5>
                              <svg>
                                <rect width="100%" height="100%"></rect>
                              </svg>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="sv-label">Services</label>
                        <div className="sv-placeholderholder">
                          <select
                            className="form-control sv-input-field"
                            required="required"
                          >
                            <option selected hidden>
                              Select your Services....{" "}
                            </option>
                            {[
                              `Our office need a cleaner for 2 days`,
                              `My wife is looking for a nanny`,
                              `A honest tiler for a small project`,
                              `Interested cooks to prepare meals for a wedding`,
                              `Any compassionate caregiver within Thomas Avenue`,
                            ].map((list) => (
                              <option>{list}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="sv-label">Title</label>
                        <div className="sv-placeholderholder">
                          <input
                            type="text"
                            className="form-control sv-input-field"
                            required="required"
                          />
                          <div className="sv-placeholder">
                            <span>Enter your Title</span>
                            <em>*</em>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="sv-label">Bio</label>
                        <div className="sv-placeholderholder">
                          <input
                            type="text"
                            className="form-control sv-input-field"
                            required="required"
                          />
                          <div className="sv-placeholder">
                            <span>Enter your Bio</span>
                            <em>*</em>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="sv-label">Select Location</label>
                        <div className="sv-placeholderholder">
                          <input
                            type="email"
                            className="form-control sv-input-field"
                            required="required"
                          />
                          <div className="sv-placeholder">
                            <span>Enter your Select Location</span>
                            <em>*</em>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="sv-check">
                          <input
                            type="checkbox"
                            id="bhourscheck2"
                            name="bhourscheck"
                          />
                          <label htmlFor="bhourscheck2">
                            <a href="javascript:void(0);">
                              {" "}
                              Terms and condition
                            </a>{" "}
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          name="submit"
                          className="sv-pb-lg w-100"
                        >
                          {" "}
                          <span>Sign up</span>
                          <i className="icon-arrow-right" />
                        </button>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
          {/* login START */}
        </main>
        {/* MAIN END */}
      </ClientLayout>
    </>
  );
};

export default ClientPostaJob;
